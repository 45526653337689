import { Controller } from 'stimulus';
import { debounce } from 'lodash';
import Swiper, { A11y, Navigation, Pagination } from 'swiper';

Swiper.use([A11y, Navigation, Pagination]);

export default class extends Controller {
  static targets = ['slider', 'pagination', 'next', 'prev'];

  connect() {
    this.currentBreakpoint = this.getCurrentBreakpoint();

    this.initSwiper();
    this.bindEvents();
    this.handleResize();
  }

  disconnect() {
    this.destroySwiper();
    this.bindEvents(true);
  }

  /**
   * initSwiper
   * - Initialize the Swiper instance
   */
  initSwiper() {
    this.swiper = new Swiper(this.sliderTarget, {
      slidesPerView: 1,
      spaceBetween: 10,
      a11y: {
        enabled: true,
      },
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },
      speed: 650,
      breakpoints: {
        700: {
          slidesPerView: 1,
          centeredSlides: true,
          spaceBetween: 42,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 48,
        },
      },
    });

    // Hack-fix for Swiper pagination not updating after a swipe gesture
    this.swiper.on('slideChangeTransitionEnd', () => {
      // Swiper pagination does not hold the correct index when swiping when there are multiple Swiper instances
      // Short-circuit if pagination does not exist
      if (!this.swiper.pagination) return;
      this.swiper.pagination.render();
      this.swiper.pagination.update();
    });
  }

  /**
   * handleBeforeCache
   * - When the Turbolinks cache is about to be cleared, destroy the Swiper instance
   */
  handleBeforeCache() {
    this.destroySwiper();
  }

  /**
   * destroySwiper
   * - Destroy the Swiper instance, if it exists
   */
  destroySwiper() {
    if (this.swiper) {
      this.swiper.destroy(true, true);
    }
  }

  /**
   * handleResize
   * - Actions to occur on resize
   */
  handleResize() {
    // Since there is the possibility for resize to not shift the slides appropriately to the start position when
    //  resizing from tablet to desktop size.  We want to ensure that the swiper is destroyed and re-initialized,
    // forcing the slides to be in the correct position for each screen size.
    // We only want to trigger this if the breakpoint has changed, since iPhones trigger resize events when scrolling,
    //  which would cause the swiper to be destroyed and re-initialized on every scroll event.
    const newBreakpoint = this.getCurrentBreakpoint();

    if (this.currentBreakpoint !== newBreakpoint) {
      this.currentBreakpoint = newBreakpoint;

      this.destroySwiper();
      this.initSwiper();
    }
  }

  /**
   * getCurrentBreakpoint
   * - Get the current breakpoint name based on the window width
   * @returns {string}
   */
  getCurrentBreakpoint() {
    const windowWidth = window.innerWidth;

    if (windowWidth < 1200) {
      return 'mobile-tablet';
    } else {
      return 'desktop';
    }
  }

  /**
   * bindEvents
   * - Bind event listeners for this component
   * @param {boolean} unbind - Whether to unbind the events, defaults to false
   */
  bindEvents(unbind = false) {
    if (unbind) {
      document.removeEventListener('turbolinks:before-cache', this.handleBeforeCache.bind(this));
      if (!this.debouncedHandleResize) return;
      window.removeEventListener('resize', this.debouncedHandleResize);
      return;
    }
    document.addEventListener('turbolinks:before-cache', this.handleBeforeCache.bind(this));
    this.debouncedHandleResize = debounce(this.handleResize.bind(this), 150);
    window.addEventListener('resize', this.debouncedHandleResize);
  }
}
